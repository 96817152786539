import React, { useEffect } from 'react';
import { Box, Grid, Typography, Hidden, Stack, Divider } from '@mui/material';
import {
  changeDashboardDateFilterStoreAction,
  fetchGenerateUserReport,
  fetchUsersCountStoreAction,
} from '../../dashboard-actions';
import { DashboardRegisterUserItem } from './DashboardRegisterUserItem';
import { DashboardUserByMonthBar } from './DashboardUserByMonthBar';
import { DashboardUserProviderBar } from './DashboardUserProviderBar';
import { DashboardWinnerNoteContainer } from './DashboardWinnerNoteContainer';
import { DashboardRegisterUsersTable } from './DashboardRegisterUsersTable';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { useAuth } from '../../../auth/auth-hook';
import { fetchUserByMonth } from '../../../user/user-actions';
import { DateRangePicker } from '../../../../shared/components/DateRangePicker';
import { sanitizeCustomDate } from '../../../../shared/utils';
import { DateFilter } from '../../../../shared/constants';
import { DashboardTotalUserItem } from './DashboardTotalUserItem';
import { DashboardGeneralDateText } from '../DashboardGeneralDateText';
import moment from 'moment';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import * as XLSX from 'xlsx';
import { getDateFilterText } from '../../dashboard-utils';
import { saveAs } from 'file-saver';
import { openSnackbarAction } from '../../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../../shared/components/snackbar/snackbar-types';

export const DashboardGeneralTab: React.FC = () => {
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const { tenant, user } = useAuth();
  const isVanguardia = tenant?.name?.toLowerCase().includes('vanguardia');
  const [callFetchUser] = useCallAction(fetchUserByMonth, {
    onCompleted: (data) => {
      fetchUsersCountStoreAction(false, data);
    },
    onError: () => {
      fetchUsersCountStoreAction(false, []);
    },
  });

  const [callReport, loadingReport] = useCallAction(fetchGenerateUserReport, {
    onCompleted: ({ report }) => {

      if (!report || report?.length === 0) {
        openSnackbarAction(
          'Su reporte estara disponible en unos minutos',
          TypeMessage.SUCCESS,
        );
        return;
      }


      const worksheet = XLSX.utils.json_to_sheet(
        report.map((r) => {
          return {
            fecha: r.date
              ? moment(r.date).format('YYYY-MM-DD HH:mm')
              : moment(r.created_at).format('YYYY-MM-DD HH:mm'),
            nombre: r.name,
            correo: r.email,
            canal: r.provider !== 'SYSTEM' ? r.provider : 'Correo eletrónico',
            canal_id: r.provider_id,
            'url de registro': r.url_register,
            sex: r.sex || r.gender,
          };
        }),
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `${getDateFilterText(from, to, filter)}`,
      );
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });
      saveAs(blob, `Reporte ${getDateFilterText(from, to, filter)}.xlsx`);
    },
  });

  useEffect(() => {
    if (tenant) {
      callFetchUser({
        tenant,
        from,
        to,
        filter: filter,
      });
    }
  }, [tenant, callFetchUser, from, to, filter]);

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              alignItems="center">
              <Typography>Informe general de registro de usuarios</Typography>
              <Hidden smUp>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  width="100%"
                  py="5px">
                  <ButtonDefault
                    onClick={() =>
                      callReport({
                        user, 
                        tenant,
                        from: '',
                        to: '',
                      })
                    }
                    startIcon={
                      !loadingReport ? (
                        <img
                          src="/icon-clip.svg"
                          alt="clip"
                          width={14}
                          height={14}
                        />
                      ) : null
                    }
                    isLoading={loadingReport}
                    style={{
                      width: 195,
                      height: 40,
                      textTransform: 'inherit',
                      fontWeight: 700,
                      background: tenant?.identityColor ?? 'black',
                    }}>
                    Descargar reporte
                  </ButtonDefault>
                </Box>
              </Hidden>

              <Box
                display="flex"
                alignItems="center"
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    justifyContent: 'flex-end',
                  },
                })}>
                <Hidden smDown>
                  <ButtonDefault
                    onClick={() =>
                      callReport({
                        user,
                        tenant,
                        from: '',
                        to: '',
                      })
                    }
                    sx={{ marginRight: '5px' }}
                    startIcon={
                      !loadingReport ? (
                        <img
                          src="/icon-clip.svg"
                          alt="clip"
                          width={14}
                          height={14}
                        />
                      ) : null
                    }
                    isLoading={loadingReport}
                    style={{
                      width: 195,
                      height: 40,
                      textTransform: 'inherit',
                      fontWeight: 700,
                      background: tenant?.identityColor ?? 'black',
                    }}>
                    Descargar reporte
                  </ButtonDefault>
                </Hidden>
                <DashboardGeneralDateText />
                <DateRangePicker
                  onChange={(range) => {

                    const dateFilter = sanitizeCustomDate(
                      range.key as DateFilter,
                      {
                        startDate: range.startDate,
                        endDate: range.endDate,
                      },
                    );

                    changeDashboardDateFilterStoreAction(
                      moment(range.startDate).startOf('day').utc(true).format(),
                      moment(range.endDate).endOf('day').utc(true).format(),
                      dateFilter,
                    );



                    fetchUsersCountStoreAction(true, []);
                  }}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <DashboardTotalUserItem />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardRegisterUserItem />
          </Grid>

          {/*<Grid item xs={12} md={6}>*/}
          {/*  <DashboardLoginUserSession />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6}>*/}
          {/*  <DashboardAnonymousUserSession />*/}
          {/*</Grid>*/}
          <Grid item xs={12} md={isVanguardia ? 12 : 6}>
            <DashboardUserByMonthBar />
          </Grid>
          <Grid item xs={12} md={isVanguardia ? 12 : 6}>
            <DashboardUserProviderBar />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ margin: '10px 0px !important' }} />
          </Grid>
          <Grid item xs={12}>
            <DashboardWinnerNoteContainer />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: '20px', marginBottom: '0px' }} />
          </Grid>
          <Grid item xs={12}>
            <DashboardRegisterUsersTable />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
