export enum StatusReport {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface ReportType {
  status: StatusReport;
  name: string;
  id: number;
  created_at: Date | null;
  updated_at: Date | null;
  report: string;
  status_message: string | null;
  requested_by: string;
}
