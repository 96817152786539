import React, { useEffect, useRef, useState } from 'react';
import { Box, Popover, Paper, IconButton, Tooltip } from '@mui/material';
import {
  RangeKeyDict,
  Range,
  DateRangePicker as DateRangePickerMui,
  StaticRange,
} from 'react-date-range';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import { DashboardGeneralDateText } from '../../modules/dashboard/components/DashboardGeneralDateText';
import { createEvent, useSubscription } from '@cobuildlab/react-simple-state';
import moment from 'moment-timezone';

const [a,b] = [moment().startOf('day').toDate(), moment().endOf('day').toDate()];


const defaultParams = {
  isSelected() {
    return true;
  },
  hasCustomRendering: false,
};

const STATIC_RANGES = [
  {
    label: 'Hoy',
    hasCustomRendering: true,
    range: () => ({
      startDate: moment().startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'today',
    }),
    isSelected() {
      return true;
    },
  },
  {
    label: 'Ayer',
    range: () => ({
      startDate: moment().subtract(1, 'day').startOf('day').toDate(),
      endDate: moment().subtract(1, 'day').endOf('day').toDate(),
      key: 'yesterday',
    }),
    ...defaultParams,
  },
  {
    label: 'Esta semana',
    range: () => ({
      startDate: moment().startOf('week').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'currentWeek',
    }),
    ...defaultParams,
  },
  {
    label: 'Semana Anterior',
    range: () => ({
      startDate: moment().subtract(1, 'week').startOf('week').toDate(),
      endDate: moment().subtract(1, 'week').endOf('week').toDate(),
      key: 'lastWeek',
    }),
    ...defaultParams,
  },
  {
    label: 'Este mes',
    range: () => ({
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'currentMonth',
    }),
    ...defaultParams,
  },
  {
    label: 'Mes Anterior',
    range: () => ({
      startDate: moment().subtract(1, 'month').startOf('month').toDate(),
      endDate: moment().subtract(1, 'month').endOf('month').toDate(),
      key: 'lastMonth',
    }),
    ...defaultParams,
  },
  {
    label: 'Este año',
    range: () => ({
      startDate: moment().startOf('year').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'currentYear',
    }),
    ...defaultParams,
  },
];

export const openRangePickerEvent = createEvent<boolean>({
  initialValue: false,
});

type DateRangePickerProps = {
  onChange: (ranges: Range) => void;
};
export const DateRangePicker: React.FC<DateRangePickerProps> = (
  {
    onChange,
  }) => {


  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const [ranges, setRanges] = useState<Range[]>([
    {
      startDate: moment().startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'today',
    },
  ]);

  const handleDates = (rangesByKey: RangeKeyDict): void => {
    const entries = Object.entries(rangesByKey);


    if (entries.length) {
      const [, value] = entries[0];
      const startDate = moment(value.startDate).utcOffset(0).format('YYYY/MM/DD HH:mm');
      const endDate = moment(value.endDate).utcOffset(0).format('YYYY/MM/DD HH:mm');


      let defaultRange = {
        ...value,
      };

      const staticRanges = STATIC_RANGES.map(({ range }) => range()).map(
        (range) => ({
          startDate: moment(range.startDate).utcOffset(0).format('YYYY/MM/DD HH:mm'),
          endDate: moment(range.endDate).utcOffset(0).format('YYYY/MM/DD HH:mm'),
          key: range.key,
        }),
      );

      const isFilter = staticRanges.find(
        (staticRange) =>
          staticRange.startDate === startDate &&
          staticRange.endDate === endDate,
      );

      if (!isFilter) {
        defaultRange = {
          ...defaultRange,
          key: 'custom',
        };
      }


      setRanges([defaultRange]);
      onChange(defaultRange);
    }
  };

  useSubscription(openRangePickerEvent, (isOpen) => {
    if (isOpen) {
      setAnchorEl(buttonRef.current);
    }
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    openRangePickerEvent.dispatch(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box>
      <Tooltip title='Filtrar por fecha'>
        <IconButton onClick={handleClick} ref={buttonRef}>
          <FilterAltOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
          <DateRangePickerMui
            renderStaticRangeLabel={(staticRange: StaticRange) => (
              <Box>{staticRange.label}</Box>
            )}
            onChange={handleDates}
            ranges={ranges}
            inputRanges={[]}
            staticRanges={STATIC_RANGES}
          />
        </Paper>
      </Popover>
    </Box>
  );
};

export const DateRangePickerWithText: React.FC<DateRangePickerProps> = ({
                                                                          onChange,
                                                                        }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const [ranges, setRanges] = useState<Range[]>([
    {
      startDate: moment().utcOffset(0).startOf('day').toDate(),
      endDate: moment().utcOffset(0).endOf('day').toDate(),
      key: 'today',
    },
  ]);

  const handleDates = (rangesByKey: RangeKeyDict): void => {
    const entries = Object.entries(rangesByKey);
    if (entries.length) {
      const [, value] = entries[0];
      const startDate = moment(value.startDate).utcOffset(0).format('YYYY/MM/DD HH:mm');
      const endDate = moment(value.endDate).format('YYYY/MM/DD HH:mm');
      let defaultRange = {
        ...value,
      };
      const staticRanges = STATIC_RANGES.map(({ range }) => range()).map(
        (range) => ({
          startDate: moment(range.startDate).utcOffset(0).format('YYYY/MM/DD HH:mm'),
          endDate: moment(range.endDate).utcOffset(0).format('YYYY/MM/DD HH:mm'),
          key: range.key,
        }),
      );

      const isFilter = staticRanges.find(
        (staticRange) =>
          staticRange.startDate === startDate &&
          staticRange.endDate === endDate,
      );


      if (!isFilter) {
        defaultRange = {
          ...defaultRange,
          key: 'custom',
        };
      }

      setRanges([defaultRange]);
      onChange(defaultRange);
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box>
      <Tooltip title='Filtrar por fecha'>
        <Box
          onClick={handleClick}
          component='button'
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
          }}
        >
          <DashboardGeneralDateText />
        </Box>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
          <DateRangePickerMui
            renderStaticRangeLabel={(staticRange: StaticRange) => (
              <Box>{staticRange.label}</Box>
            )}
            onChange={handleDates}
            ranges={ranges}
            inputRanges={[]}
            staticRanges={STATIC_RANGES}
          />
        </Paper>
      </Popover>
    </Box>
  );
};
