import React from 'react';
import {
  TenantUserInvitation,
  TenantUserInvitationStatuses,
  TenantUserRoles,
  useUpdateTenantUserInvitationMutation,
} from '../../../shared/types/generated';
import { Box } from '@mui/material';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';

type TenantUserInvitationItemProps = {
  item: TenantUserInvitation;
};
export const TenantUserInvitationItem: React.FC<
  TenantUserInvitationItemProps
> = ({ item }) => {
  const [acceptedInvitation, { loading: acceptedLoading }] =
    useUpdateTenantUserInvitationMutation({
      onCompleted: () => {
        openSnackbarAction('Se ha aceptado correctamente', TypeMessage.SUCCESS);
        window.location.reload();
      },
      onError: (error) => {
        openSnackbarAction(error.message, TypeMessage.ERROR);
      },
    });

  const [rejectedInvitation, { loading: rejectedLoading }] =
    useUpdateTenantUserInvitationMutation({
      onCompleted: () => {
        openSnackbarAction(
          'Se ha rechazado correctamente',
          TypeMessage.SUCCESS,
        );
        window.location.reload();
      },
      onError: (error) => {
        openSnackbarAction(error.message, TypeMessage.ERROR);
      },
    });


  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={1}
    >
      <div>
        Has sido invitado como{' '}
        {item.role === TenantUserRoles.TenantUser ? 'usuario' : 'administrador'}{' '}
        al EasyData de {item.tenant?.name}
      </div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        minWidth="200px"
      >
        <ButtonDefault
          isLoading={rejectedLoading}
          onClick={() =>
            rejectedInvitation({
              variables: {
                input: {
                  id: item?.id as string,
                  status: TenantUserInvitationStatuses.Rejected,
                },
              },
            })
          }
          variant="outlined"
          style={{
            height: 40,
            minWidth: 95,
          }}
        >
          REJECT
        </ButtonDefault>

        <ButtonDefault
          isLoading={acceptedLoading}
          onClick={() =>
            acceptedInvitation({
              variables: {
                input: {
                  id: item?.id as string,
                  status: TenantUserInvitationStatuses.Accepted,
                },
              },
            })
          }
          style={{
            height: 40,
            minWidth: 95,
          }}
        >
          ACCEPT
        </ButtonDefault>
      </Box>
    </Box>
  );
};
