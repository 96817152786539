import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
} from '@mui/material';
import { createCouponSchema } from '../../tenant-subs-plan-validator';
import { handleFormErrors } from '../../../../shared/utils/errors';
import { Close as CloseIcon } from '@mui/icons-material';
import { DatePickerDefault } from '../../../../shared/components/inputs/DatePickerDefault';
import moment from 'moment';
import { InputDefault } from '../../../../shared/components/inputs/InputDefault';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import { SelectDefault } from '../../../../shared/components/inputs/SelectDefault';
import { CreateCouponSchemaFormType } from '../../tenant-subs-plan-types';
import { TenantSubscriptionListEvent } from '../../tenant-subs-plan-events';
import { useEvent } from '@cobuildlab/react-simple-state';
import { TooltipHelper } from '../../../../shared/components/tooltip/TooltipHelper';
import { CouponRules } from './CouponRules';
import { ShareCouponInput } from './ShareCouponInput';


interface CreateCouponModalProps {
  open: boolean;
  handleClose: () => void;
  loading?: boolean;
  buttonColor?: string | null;
  isAnnualPlan?: boolean;
  onSubmit: (
    data: CreateCouponSchemaFormType,
    sharedEmails?: string[],
    referralEmails?: string[],
  ) => void;
}

export const CreateCouponModal: React.FC<CreateCouponModalProps> = ({
  handleClose,
  open,
  loading = false,
  buttonColor,
  onSubmit,
  isAnnualPlan = false,
}) => {
  const [sharedEmails, setSharedEmails] = useState<string[]>([]);
  const [isValidSharedEmails, setIsValidSharedEmails] = useState<boolean>(true);
  const [referralEmails, setReferralEmails] = useState<string[]>([]);
  const [isValidReferralEmails, setIsValidReferralEmails] = useState<boolean>(true);
  const plans = useEvent(TenantSubscriptionListEvent);
  const [planOptions, setPlanOptions] = useState<
    { value: string; label: string }[]
  >([
    {
      value: 'all',
      label: 'Todos los planes',
    },
  ]);

  const [planOptionSelected, setPlanOptionSelected] = useState<
    { value: string; label: string }[]
  >([
    {
      value: 'all',
      label: 'Todos los planes',
    },
  ]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid: isValidForm },
    watch,
  } = useForm({
    resolver: yupResolver(createCouponSchema),
    defaultValues: {
      discount: 0,
      discountType: 'percent',
      isForSharing: false,
      isPublic: false,
    },
  });

  const watchDiscountType = watch('discountType');
  const isValid = isValidForm && isValidSharedEmails && isValidReferralEmails;

  const onFormSubmit = handleSubmit(
    async (dataForm) => {
      onSubmit(
        {
          ...dataForm,
          plans: planOptionSelected.map((plan) => plan.value),
        },
        sharedEmails,
        referralEmails,
      );
    },
    (error) => {
      handleFormErrors(error);
    },
  );

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  useEffect(() => {
    if (plans && plans.data) {
      setPlanOptions([
        {
          value: 'all',
          label: 'Todos los planes',
        },
        ...plans.data
          .filter((plan) => plan.status === 'ACTIVE')
          .map((plan) => ({
            value: plan.id,
            label: plan.title,
          })),
      ]);
    }
  }, [plans]);

  useEffect(() => {
    if (planOptionSelected.length === 0) {
      setPlanOptionSelected([
        {
          value: 'all',
          label: 'Todos los planes',
        },
      ]);
    }
  }, [planOptionSelected.length]);

  const ActivePlans = (): JSX.Element => (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {planOptionSelected.map((item, index) => (
        <Chip
          key={index}
          label={item.label}
          variant="filled"
          size="small"
          sx={{ mt: 1 }}
          onDelete={() =>
            setPlanOptionSelected(
              planOptionSelected.filter(
                (selectedPlan) => selectedPlan !== item,
              ),
            )
          }
        />
      ))}
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        reset();
      }}
      fullWidth
      maxWidth="md">
      <DialogTitle color="black" fontWeight={700}>
        Crear cupon
        <TooltipHelper value={<CouponRules />} />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          handleClose();
          reset();
        }}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={onFormSubmit}
        sx={{
          padding: 2,
          margin: '0 auto',
          px: 3,
        }}>
        <Grid container spacing={2}>
          <Grid item xs={7} sm={6}>

            <Controller
              name="discount"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <InputDefault
                  {...field}
                  fullWidth
                  labelName="Descuento"
                  variant="outlined"
                  type="number"
                  endIcon={
                    <InputAdornment position="end">
                      {watchDiscountType === 'amount' ? 'MXN' : '%'}
                    </InputAdornment>
                  }
                  error={!!errors.discount}
                  helperText={errors.discount?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={5} sm={6}>

            <Controller
              name="discountType"
              control={control}
              defaultValue={'percent'}
              render={({ field }) => (
                <SelectDefault
                  {...field}
                  fullWidth
                  labelName="Tipo"
                  variant="outlined"
                  type="number"
                  options={[
                    { label: 'Porcentaje', value: 'percent' },
                    { label: 'Cantidad fija', value: 'amount' },
                  ]}
                  error={!!errors.discountType}
                  helperText={errors.discountType?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <InputDefault
                  {...rest}
                  fullWidth
                  labelName="Nombre de cupón"
                  placeholder="Ingrese nombre del cupón"
                  variant="outlined"
                  type="text"
                  error={!!errors.maxRedemptions}
                  helperText={errors.maxRedemptions?.message}
                  onChange={(e) => {
                    const inputValue = e.target.value.toUpperCase();
                    const regex = /^[a-zA-Z0-9-]*$/;

                    if (regex.test(inputValue)) {
                      e.target.value = inputValue;
                      onChange(e);
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectDefault
              fullWidth
              labelName="Planes"
              variant="outlined"
              placeholder="Seleccione un plan"
              type="text"
              options={planOptions}
              error={!!errors.discountType}
              helperText={errors.discountType?.message}
              onChangeData={(field, value) => {
                if (value === 'all') {
                  setPlanOptionSelected([
                    {
                      value,
                      label: 'Todos los planes',
                    },
                  ]);
                } else {
                  setPlanOptionSelected([
                    ...planOptionSelected.filter(
                      (plan) => plan.value !== 'all',
                    ),
                    {
                      value,
                      label: planOptions.find((plan) => plan.value === value)
                        ?.label as string,
                    },
                  ]);
                }
              }}
            />
            <ActivePlans />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="maxRedemptions"
              control={control}
              render={({ field }) => (
                <InputDefault
                  {...field}
                  fullWidth
                  labelName="Máximo de Redenciones"
                  placeholder="Ingrese el máximo de redenciones"
                  variant="outlined"
                  type="number"
                  error={!!errors.maxRedemptions}
                  helperText={errors.maxRedemptions?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name={'expirationDate'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePickerDefault
                  labelName="Fecha de Vencimiento"
                  textFieldProps={{
                    helperText: errors.expirationDate?.message,
                    error: !!errors.expirationDate?.message,
                    placeholder: 'DD/MM/AAAA',
                  }}
                  value={value && value !== '' ? moment(value as string) : null}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          {!isAnnualPlan && (
            <Grid item xs={12} sm={6}>
              <Controller
                name={'durationInMonths'}
                control={control}
                render={({ field }) => (
                  <InputDefault
                    {...field}
                    fullWidth
                    labelName="Cantidad de meses a aplicar"
                    variant="outlined"
                    type="number"
                    placeholder="Ingrese el número de meses"
                    endIcon={
                      <InputAdornment position="end">Meses</InputAdornment>
                    }
                    error={!!errors.durationInMonths}
                    helperText={errors.durationInMonths?.message}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <ShareCouponInput
              labelName="Asignar para compartir"
              placeholder="Correo electrónico de usuarios ya registrados"
              onChange={(emails) => setReferralEmails(emails)}
              onValidField={(isValidShared) => setIsValidReferralEmails(isValidShared)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ShareCouponInput
              labelName="Compartir con"
              placeholder="Ingrese el correo electrónico"
              onChange={(emails) => setSharedEmails(emails)}
              onValidField={(isValidShared) => setIsValidSharedEmails(isValidShared)}
            />
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <Controller
                name={'isForSharing'}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label="Permitir que este disponible para que cualquier usuario y se pueda compartir."
                    control={
                      <Checkbox
                        color="secondary"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                  />
                )}
              />
              <Controller
                name={'isPublic'}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ marginTop: 2 }}
                    label="Permitir que este disponible para que cualquier usuario."
                    control={
                      <Checkbox
                        color="secondary"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <ButtonDefault
              type="submit"
              fullWidth
              disabled={!isValid}
              isLoading={loading}
              style={{
                textTransform: 'inherit',
                fontWeight: 700,
                background: buttonColor ?? undefined,
                ...(!isValid && { background: '#d3d3d3' }),
              }}>
              Crear Cupón
            </ButtonDefault>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
