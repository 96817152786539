import React, { useEffect, useState } from 'react';
import { TableDefaultSticky } from '../../../../shared/components/table/TableDefaultSticky';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import {
  useCallAction,
  useEvent,
  useStore,
} from '@cobuildlab/react-simple-state';
import { fetchGlobalRegisterUsers } from '../../../user/user-actions';
import { fetchGlobalRegisterUsersEvent } from '../../../user/user-events';
import { GlobalUserJoinSocialProvider } from '../../../user/user-types';
import { getDateByTimezone } from '../../../../shared/utils';
import { useAuth } from '../../../auth/auth-hook';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { fetchGenerateUserReport } from '../../dashboard-actions';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { getDateFilterText } from '../../dashboard-utils';
import { DashboardPopoverLinkItem } from '../DashboardPopoverLinkItem';
import { useStylesCustom } from '../DashboardCardItem';
import { useTableSort } from '../../../../shared/components/table/useTableSort';
import { openSnackbarAction } from '../../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../../shared/components/snackbar/snackbar-types';

export const DashboardRegisterUsersTable: React.FC = () => {
  const { data, total } = useEvent(fetchGlobalRegisterUsersEvent);
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const [callFetchUsers, loading] = useCallAction(fetchGlobalRegisterUsers);
  const { sort, handleSort } = useTableSort();

  const [callReport, loadingReport] = useCallAction(fetchGenerateUserReport, {
    onCompleted: ({ report }) => {
      console.log({ report });
      if (!report || report?.length === 0) {
        openSnackbarAction(
          'Su reporte estara disponible en unos minutos',
          TypeMessage.SUCCESS,
        );
        return;
      }
      const worksheet = XLSX.utils.json_to_sheet(
        report.map((r) => {
          return {
            fecha: r.date
              ? moment(r.date).format('YYYY-MM-DD HH:mm')
              : moment(r.created_at).format('YYYY-MM-DD HH:mm'),
            nombre: r.name,
            correo: r.email,
            canal: r.provider !== 'SYSTEM' ? r.provider : 'Correo eletrónico',
            canal_id: r.provider_id,
            'url de registro': r.url_register,
            sex: r.sex || r.gender,
          };
        }),
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `${getDateFilterText(from, to, filter)}`,
      );
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });
      saveAs(blob, `Reporte ${getDateFilterText(from, to, filter)}.xlsx`);
    },
  });

  const { tenant, user } = useAuth();

  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  useEffect(() => {
    if (tenant) {
      callFetchUsers({
        tenant,
        from,
        to,
        page: pagination.page,
        pageSize: pagination.pageSize,
        orderBy: sort,
      });
    }
  }, [
    callFetchUsers,
    from,
    pagination.page,
    pagination.pageSize,
    tenant,
    to,
    sort,
  ]);

  const COLUMNS: TableColumnType<GlobalUserJoinSocialProvider>[] = [
    {
      columnName: (
        <Box display="flex" color={'#BBBBBB'} alignItems="center">
          <Typography color={'#BBBBBB'}>FECHA DE CREACIÓN</Typography>
        </Box>
      ),
      columnValue: (item: GlobalUserJoinSocialProvider) =>
        item.date || item.created_at,
      sortableField: 'user.created_at',
    },
    {
      columnName: <Typography color={'#BBBBBB'}>NOMBRE</Typography>,
      columnValue: null,
      columnComponent: (item: GlobalUserJoinSocialProvider) => (
        <Box textAlign="left">{item.name as string}</Box>
      ),
      sortableField: 'user.name',
    },
    {
      columnName: <Typography color={'#BBBBBB'}>URL DE REGISTRO</Typography>,
      columnValue: null,
      columnComponent: (item: GlobalUserJoinSocialProvider) => (
        <Box width="100%" justifyContent="left" display="flex">
          <DashboardPopoverLinkItem url={item.url_register ?? ''} />
        </Box>
      ),
      sortableField: 'social_provider.url_register',
    },
    {
      columnName: <Typography color={'#BBBBBB'}>EMAIL</Typography>,
      columnValue: null,
      columnComponent: (item: GlobalUserJoinSocialProvider) => (
        <Box textAlign="left" color={'#BBBBBB'}>
          {item.email as string}
        </Box>
      ),
      sortableField: 'user.email',
    },
    {
      columnName: <Typography color={'#BBBBBB'}>CANAL</Typography>,
      columnValue: null,
      columnComponent: (item: GlobalUserJoinSocialProvider) => (
        <Typography fontSize={14} fontWeight={700}>
          {item.provider}
        </Typography>
      ),
      sortableField: 'social_provider.provider',
    },
  ];
  const classes = useStylesCustom();
  return (
    <>
      {/**<CardSection
       title="Usuarios registrados"
       actions={
       <ButtonDefault
       onClick={() =>
       callReport({
       tenant,
       from,
       to,
       })
       }
       isLoading={loadingReport}
       style={{ width: 195, height: 45 }}
       >
       Descargar reporte
       </ButtonDefault>
       }
       > */}
      <Box
        marginTop={1}
        marginBottom={2}
        sx={{
          display: { xs: 'block', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        alignItems={'center'}>
        <Typography
          component={'div'}
          sx={{ marginBottom: { xs: '10px', md: '0px' } }}
          fontSize={20}
          fontWeight={700}>
          Usuarios registrados
        </Typography>
        <ButtonDefault
          onClick={() =>
            callReport({
              user,
              tenant,
              from,
              to,
            })
          }
          startIcon={
            !loadingReport ? (
              <img src="/icon-clip.svg" alt="clip" width={14} height={14} />
            ) : null
          }
          isLoading={loadingReport}
          style={{
            width: 195,
            height: 40,
            textTransform: 'inherit',
            fontWeight: 700,
            background: tenant?.identityColor ?? 'black',
          }}>
          Descargar Reporte
        </ButtonDefault>
      </Box>
      <Card
        elevation={2}
        style={{
          border: '1px solid #BBBBBB',
          padding: '16px',
          borderRadius: '16px',
          boxShadow: '0px 0px 8px 0px #00000014',
        }}>
        <CardContent className={classes.content}>
          <TableDefaultSticky
            count={total}
            items={data}
            columns={COLUMNS}
            page={pagination.page}
            pageSize={pagination.pageSize}
            loading={loading}
            messageLoading={'Cargando usuarios registrados...'}
            handleChangePage={(page) => {
              if (tenant) {
                setPagination({
                  ...pagination,
                  page: page + 1,
                });
              }
            }}
            rowsPerPageOptions={[10, 20, 50, 100]}
            handleChangeRowPerPage={(e) => {
              setPagination({
                ...pagination,
                pageSize: parseInt(e.target.value, 10),
                page: 1,
              });
            }}
            handleSort={handleSort}
          />
        </CardContent>
      </Card>
    </>
  );
};
