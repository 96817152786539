import { createEvent, createStore } from '@cobuildlab/react-simple-state';
import { CountData } from '../dashboard/dashboard-types';
import {
  GlobalSubscriptionUser,
  SubscriptionsByPrice,
  SubscriptionsEvent,
} from './subscription-types';

export const fetchSubscriptionCountEvent = createEvent<{ count: number }>({
  initialValue: {
    count: 0,
  },
});

export const fetchSubscriptionCountErrorEvent = createEvent<Error>();

export const fetchSubscriptionByMonthsEvents = createEvent<
  { count: number; date: string }[]
>({
  initialValue: [],
});

export const fetchSubscriptionByMonthsErrorEvents = createEvent<Error>();

export const fetchSubscriptionCountStore = createStore<{
  loading: boolean;
  subscriptions: CountData[];
}>({
  initialValue: {
    loading: true,
    subscriptions: [],
  },
});

export const fetchSubscriptionByPaymentMethodEvents = createEvent<
  { count: number; payment_method: string }[]
>({
  initialValue: [],
});

export const fetchSubscriptionByPaymentMethodErrorEvents = createEvent<Error>();

export const fetchSubscriptionsEvent = createEvent<SubscriptionsEvent>({
  initialValue: {
    data: [],
    total: 0,
    paymentMethods: [],
  },
});

export const fetchSubscriptionsErrorEvent = createEvent();

export const fetchSubscriptionsReportEvent = createEvent<{
  report: GlobalSubscriptionUser[];
}>({
  initialValue: {
    report: [],
  },
});

export const fetchSubscriptionsReportErrorEvent = createEvent<Error>();

export const fetchSubscriptionActiveByMonthsEvents = createEvent<
  { count: number; date: string }[]
>({
  initialValue: [],
});

export const fetchSubscriptionActiveByMonthsErrorEvents = createEvent<Error>();

export const fetchSubscriptionActiveTotalEvents = createEvent<{
  count: number;
}>({
  initialValue: {
    count: 0,
  },
});

export const fetchSubscriptionActiveTotalErrorEvents = createEvent<Error>();

export const fetchTotalSubscriptionsReportEvent = createEvent<{
  report: GlobalSubscriptionUser[];
}>({
  initialValue: {
    report: [],
  },
});

export const fetchTotalSubscriptionsReportErrorEvent = createEvent<Error>();

export const fetchSubscriptionsByPriceEvent = createEvent<SubscriptionsByPrice>(
  {
    initialValue: {
      totalCount: 0,
      totalEarnings: 0,
      data: {
        '': {
          count: 0,
          total: 0,
          price: 0,
        },
      },
    },
  },
);
export const fetchSubscriptionsByPriceEventError = createEvent<Error>();

export const cancelSubscriptionToAdminEvent = createEvent<void>();

export const cancelSubscriptionToAdminEventError= createEvent<Error>();