import { useStore } from '@cobuildlab/react-simple-state';
import { TableColumnType } from '../../../shared/components/table/table-types';
import { SwgTapByUrlDataResponseWithIdObj } from '../../analytic/analytic-types';
import { TableDefaultSticky } from '../../../shared/components/table/TableDefaultSticky';
import React, { useState } from 'react';
import {
  SwgTapByUrlOrder,
  useSwgTapByUrlReportLazyQuery,
} from '../../../shared/types/generated';
import { useAuth } from '../../auth/auth-hook';
import {
  changeGoogleDateFilterStore,
  selectedSectionStore,
} from '../dashboard-events';
import { Box, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

type DashboardGoogleWinnerNoteProps = {
  loading?: boolean;
  data?: SwgTapByUrlDataResponseWithIdObj[];
  total?: number;
};

export const DashboardGoogleWinnerNote: React.FC<
  DashboardGoogleWinnerNoteProps
> = ({ loading = false, data = [], total = 0 }) => {
  const { from, to } = useStore(changeGoogleDateFilterStore);
  const { section } = useStore(selectedSectionStore);
  const [order, setOrder] = useState<SwgTapByUrlOrder>(SwgTapByUrlOrder.Desc);
  const [callAction, { data: dataResult, loading: loadingResult }] =
    useSwgTapByUrlReportLazyQuery();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  const { tenant } = useAuth();
  const handleOrder = (orderEvent: SwgTapByUrlOrder): void => {
    if (tenant) {
      callAction({
        variables: {
          where: {
            tenantId: tenant?.id,
            from,
            to,
            order: orderEvent,
            section,
          },
          page: 0,
          pageSize: 10,
        },
      });
    }
    setOrder(orderEvent);
  };

  const COLUMNS: TableColumnType<SwgTapByUrlDataResponseWithIdObj>[] = [
    {
      columnName: (
        <Box display="flex" alignItems="center">
          <Typography>NOTAS</Typography>
          {order === 'desc' && (
            <ArrowDownward
              fontSize="small"
              onClick={() => handleOrder(SwgTapByUrlOrder.Asc)}
            />
          )}
          {order === 'asc' && (
            <ArrowUpward
              fontSize="small"
              onClick={() => handleOrder(SwgTapByUrlOrder.Desc)}
            />
          )}
        </Box>
      ),
      columnValue: null,
      columnComponent: (item: SwgTapByUrlDataResponseWithIdObj) => (
        <Box
          textAlign="left"
          sx={{ wordWrap: 'break-word', width: { xs: '150px', md: '200px' } }}
        >
          {item.url as string}
        </Box>
      ),
    },
    {
      columnName: 'LINK',
      columnValue: null,
      columnComponent: (item: SwgTapByUrlDataResponseWithIdObj) => (
        <a href={item.url as string} target="_blank">
          <LinkIcon />
        </a>
      ),
    },
    {
      columnName: 'CANTIDAD',
      columnValue: (item: SwgTapByUrlDataResponseWithIdObj) => item.count || 0,
    },
  ];

  return (
    <TableDefaultSticky
      count={total}
      items={
        (dataResult?.swgTapByUrlReport?.data ||
          data) as SwgTapByUrlDataResponseWithIdObj[]
      }
      columns={COLUMNS}
      page={pagination.page}
      pageSize={pagination.pageSize}
      loading={loading || loadingResult}
      messageLoading={'Cargando notas ganadoras...'}
      showPagination
      height={380}
      handleChangePage={(page) => {
        callAction({
          variables: {
            where: {
              from,
              to,
              tenantId: tenant?.id,
              section,
              order: SwgTapByUrlOrder.Desc,
            },
            page: page + 1 - 1,
            pageSize: pagination.pageSize,
          },
        });
        setPagination({
          ...pagination,
          page: page + 1,
        });
      }}
      rowsPerPageOptions={[10, 20, 50, 100]}
      handleChangeRowPerPage={(e) => {
        callAction({
          variables: {
            where: {
              from,
              to,
              tenantId: tenant?.id,
              section,
              order: SwgTapByUrlOrder.Desc,
            },
            page: 0,
            pageSize: parseInt(e.target.value, 10),
          },
        });

        setPagination({
          page: 1,
          pageSize: parseInt(e.target.value, 10),
        });
      }}
    />
  );
};
