import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import { SortTableInput, Tenant, User } from '../../shared/types/generated';
import axios from 'axios';
import {
  cancelSubscriptionToAdminEvent,
  cancelSubscriptionToAdminEventError,
  fetchSubscriptionActiveByMonthsErrorEvents,
  fetchSubscriptionActiveByMonthsEvents,
  fetchSubscriptionActiveTotalErrorEvents,
  fetchSubscriptionActiveTotalEvents,
  fetchSubscriptionByMonthsErrorEvents,
  fetchSubscriptionByMonthsEvents,
  fetchSubscriptionByPaymentMethodErrorEvents,
  fetchSubscriptionByPaymentMethodEvents,
  fetchSubscriptionCountErrorEvent,
  fetchSubscriptionCountEvent,
  fetchSubscriptionCountStore,
  fetchSubscriptionsByPriceEvent,
  fetchSubscriptionsByPriceEventError,
  fetchSubscriptionsErrorEvent,
  fetchSubscriptionsEvent,
  fetchSubscriptionsReportErrorEvent,
  fetchSubscriptionsReportEvent,
  fetchTotalSubscriptionsReportErrorEvent,
  fetchTotalSubscriptionsReportEvent,
} from './subscription-events';
import { DateFilter } from '../../shared/constants';
import { CountData } from '../dashboard/dashboard-types';
import {
  GlobalSubscriptionUser,
  SubscriptionMetric,
  SubscriptionsByPrice,
  SubscriptionsEvent,
} from './subscription-types';
import { subscriptionTableFilterStore } from './subscription-store';
import moment from 'moment';
import { currentTime } from '../dashboard/dashboard-events';

export const fetchSubscriptionCount = createAction(
  fetchSubscriptionCountEvent,
  fetchSubscriptionCountErrorEvent,
  async (data: { tenant: Tenant | undefined | null }) => {
    const { tenant } = data;
    const response = await axios.get<{ count: number }>(
      `${tenant?.webhook}/api/user/subscription/count`,
    );
    return {
      count: response.data.count,
    };
  },
);

export const fetchSubscriptionByMonth = createAction(
  fetchSubscriptionByMonthsEvents,
  fetchSubscriptionByMonthsErrorEvents,
  async (data: {
    tenant: Tenant;
    from: string;
    to: string;
    filter: DateFilter;
  }) => {
    const { tenant, from, to, filter } = data;
    const response = await axios.get<{ count: number; date: string }[]>(
      `${tenant.webhook}/api/user/subscription/count-by-date`,
      {
        params: {
          to,
          from,
          filter,
        },
      },
    );

    return response.data;
  },
);

export const fetchSubscriptionsCountStoreAction = createStoreAction(
  fetchSubscriptionCountStore,
  (prev, loading: boolean, subscriptions: CountData[]) => ({
    ...prev,
    loading,
    subscriptions,
  }),
);

export const fetchSubscriptionByPaymentMethod = async (data: {
  tenant: Tenant;
  from: string;
  to: string;
}): Promise<SubscriptionMetric[]> => {
  const { tenant, to, from } = data;
  const response = await axios.get<SubscriptionMetric[]>(
    `${tenant.webhook}/api/user/subscription/payment-method-count`,
    {
      params: {
        to,
        from,
      },
    },
  );

  return response.data;
};

export const fetchSubscriptionByPaymentMethodMetrics = createAction(
  fetchSubscriptionByPaymentMethodEvents,
  fetchSubscriptionByPaymentMethodErrorEvents,
  fetchSubscriptionByPaymentMethod,
);

export const fetchUserSubscriptions = createAction(
  fetchSubscriptionsEvent,
  fetchSubscriptionsErrorEvent,
  async (data: {
    tenant: Tenant;
    from: string;
    to: string;
    page: number;
    status: string;
    paymentMethod: string;
    orderBy?: SortTableInput;
  }) => {
    const { tenant, to, from, page, status, paymentMethod, orderBy } = data;

    const response = await axios.get<SubscriptionsEvent>(
      `${tenant.webhook}/api/user/subscription/all-by-filters`,
      {
        timeout: 60000,
        params: {
          to,
          from,
          page,
          status,
          paymentMethod,
          orderByField: orderBy?.field,
          orderByOrder: orderBy?.order,
        },
      },
    );
    return response.data;
  },
);

export const fetchGenerateSubscriptionUserReport = createAction(
  fetchSubscriptionsReportEvent,
  fetchSubscriptionsReportErrorEvent,
  async (data: {
    user: User | null | undefined;
    tenant: Tenant | null | undefined;
    from?: string;
    to?: string;
  }) => {
    const { tenant, to, from } = data;

    const response = await axios.get<GlobalSubscriptionUser[]>(
      `${tenant?.webhook}/api/user/subscription/generate-subscription-report`,
      {
        params: {
          requested_by: data.user?.name ?? data.user?.email,
          to,
          from,
        },
      },
    );

    return {
      report: response.data,
    };
  },
);

export const fetchGenerateTotalSubscriptionUserReport = createAction(
  fetchTotalSubscriptionsReportEvent,
  fetchTotalSubscriptionsReportErrorEvent,
  async (data: {
    user: User | null | undefined;
    tenant: Tenant | null | undefined;
    from?: string;
    to?: string;
  }) => {
    const { tenant, to, from } = data;

    const response = await axios.get<GlobalSubscriptionUser[]>(
      `${tenant?.webhook}/api/user/subscription/generate-subscription-report`,
      {
        params: {
          to,
          from,
          requested_by: data.user?.name ?? data.user?.email,
        },
      },
    );

    return {
      report: response.data,
    };
  },
);

export const fetchSubscriptionActiveByMonth = createAction(
  fetchSubscriptionActiveByMonthsEvents,
  fetchSubscriptionActiveByMonthsErrorEvents,
  async (data: {
    tenant: Tenant;
    from: string;
    to: string;
    filter: DateFilter;
  }) => {
    const { tenant, from, to, filter } = data;
    const response = await axios.get<{ count: number; date: string }[]>(
      `${tenant.webhook}/api/user/subscription/count-by-date`,
      {
        params: {
          to,
          from,
          filter,
          status: 'ACTIVE',
        },
      },
    );

    return response.data;
  },
);
export const fetchSubscriptionActiveTotal = createAction(
  fetchSubscriptionActiveTotalEvents,
  fetchSubscriptionActiveTotalErrorEvents,
  async (data: { tenant: Tenant }) => {
    const { tenant } = data;
    const response = await axios.get<{ count: number }>(
      `${tenant.webhook}/api/user/subscription/count`,
      {
        params: {
          status: 'ACTIVE',
        },
      },
    );
    return response.data;
  },
);

export const subscriptionTableFilterStoreAction = createStoreAction(
  subscriptionTableFilterStore,
  (prev, status: string, paymentMethod: string) => ({
    ...prev,
    status,
    paymentMethod,
  }),
);

export const fetchSubscriptionsByPrice = createAction(
  fetchSubscriptionsByPriceEvent,
  fetchSubscriptionsByPriceEventError,
  async (data: {
    tenant: Tenant;
    from: string;
    to: string;
    status: string;
    paymentMethod: string;
  }) => {
    const { tenant, to, from, status, paymentMethod } = data;

    const response = await axios.get<SubscriptionsByPrice>(
      `${tenant.webhook}/api/user/subscription/all-by-price`,
      {
        timeout: 60000,
        params: {
          to,
          from,
          status,
          paymentMethod,
        },
      },
    );

    return response.data;
  },
);

export const cancelSubscriptionToAdmin = createAction(
  cancelSubscriptionToAdminEvent,
  cancelSubscriptionToAdminEventError,
  async (data: { tenant: Tenant; subscriptionId: number; reason: string }) => {
    const { tenant, ...rest } = data;

console.log('data', data);
    const response = await axios.get(
      `${tenant.webhook}/api/user/subscription/admin-cancel-subscription`,
      {
        timeout: 60000,
        params: {
          ...rest,
        },
      },
    );

    return response.data;
  },
);
